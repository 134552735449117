
import React from 'react';

import '../css/sectionFourth.css';

const SectionFourth: React.FC = () => {
       

    return (
        <div className="images-container">
            <div className="imageSectionFourth">
                <img src="/images/diploma.png" alt="process" />
            </div>
            <div className="imageSectionFourth imgHiddenKnight">
                <img src="/images/knight.png" alt="process" />
            </div>
            <div className="imageSectionFourth imgHiddenLabak">
                <img src="/images/labak.png" alt="process" />
            </div>
        </div>
    );
}

export default SectionFourth;

