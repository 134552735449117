
import React from 'react';

import '../css/footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="navbar-footer-info">
                <div className='infoContainer infoContainerFirst'>
                    <div className="text-info bold companyName underLine">Art4, s.r.o.</div>
                    {/* <div className="text-info bold">Headquarters</div> */}
                    {/* <div className="text-info">Vajnorská 167 831 04 Bratislava</div> */}
                    <div className="text-info companyName companyNameHidden"><span className='bold'>&nbsp;</span></div>
                    <div className="text-info"><span className='bold'>IČO:&nbsp;</span>36869716</div>
                    <div className="text-info"><span className='bold'>DIČ:&nbsp;</span>2023124653</div>
                    <div className="text-info"><span className='bold'>IČ&nbsp;DPH:&nbsp;</span>SK2023124653</div>
                </div>
                <div className='infoContainer infoContainerSecond'>
                    <div className="text-info bold companyName companyNameHidden underLineTransparent">&nbsp;</div>
                    <div className="text-info bold">Headquarters<span className="text-info bold companyName">&nbsp;</span></div>
                    <div className="text-info">Vajnorská 167 831 04 Bratislava</div>
                    <div className="text-info bold">Office</div>
                    <div className="text-info">Čajakova 18, 811 05 Bratislava</div>
                </div>

                <div className='infoContainer infoContainerThird'>
                    <div className="text-info bold companyName companyNameHidden underLineTransparent">&nbsp;</div>
                    <div className='text-info bold'>Contact us<span className="text-info bold companyName">&nbsp;</span></div>
                    <div className="text-info">+421 905 120 000</div>
                    <div className="text-info ">info@art4.sk</div>
                    <div className="text-info last-info">www.eduplanet.ai</div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
