
import React from 'react';

import '../css/sectionFirst.css';

const SectionFirst: React.FC = () => {
    return (
        <section className='sectionFirst-container first-boxes' >
            <div className="LeftContainer">
                <div className="boxText">
                    <h2>QUICK RESPONSES, ACCURATE RESULTS. MINE PRECISE INFORMATION FROM ANY DOCUMENT</h2>
                    <p className='sectionFirst-text first-text'>
                        A personal virtual assistant ready to answer your questions 24/7. 
                        How to have exact knowledge from any document in the palm of your hand? 
                        You’ve certainly been in a situation where you kinda knew the answer to the 
                        question but weren’t 100% sure. It surely would have been very handy to have a 
                        reliable source of help at hand. We have harnessed the power of AI to optimize 
                        the extraction of necessary sources to create precise answers to any questions 
                        you might have.
                    </p>
                </div>
                <div className="boxText">
                    <h2>WHEN TO USE EDUPLANET?</h2>
                    <p className='sectionFirst-text'>
                        Is there a document you would like to consult in your everydays life? 
                        Are you about to create a new interactive learning course and you would like to provide 
                        your students with the ability to go at their own pace? Simply turn to EDUPLANET, 
                        enter your documents and your question and in an instant we will generate the accurate 
                        answer guaranteed by exact references to your document.
                    </p>
                </div>
            </div>
            <div className="rightContainer ">
                <div className="boxText">
                    <h2>HOW DOES EDUPLANET WORK?</h2>
                    <p className='sectionFirst-text third-text'>
                        The learning procedure takes the shape of natural conversation with everchanging 
                        recommendations of questions you might want to ask, as well as 
                        pointers to similar topics. EDUPLANET provides you with proactive dialogue 
                        voiced by AI and fluently guides you through the document. Text-to-speech and 
                        speech-to-text functionalities are provided by the state-of-the-art AI models 
                        and can be easily interchangeable or turned off completely. Same goes for the 
                        pregenerated human-like 3D avatar fully lip-synced with the audio representation 
                        of your answer. With EDUPLANET you can easily generate entry and final tests to 
                        give your student a reference of his level (both open questions as well as the 
                        single choice versions are available). After mastering the learning process, 
                        students can easily generate a summarization of the topics covered in this lecture. 
                        If you don’t feel like finishing your course today, you can get back to your chat 
                        anytime and continue where you left off. Your curiosity and endurance are your only 
                        limits.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default SectionFirst;
