
import React from 'react';

import '../css/LibralySection.css';

const TelescopSection: React.FC = () => {
    return (
        <section className="libraly-section">
            <div className="libraly-content">
                <div className='textContainerLibraly'>
                    <p>Your personal digital assistant for chatting 
                        with your documents, based on the synergy of 
                        AI and human knowledge and practice.</p>
                    {/* <span className='batch'>AI<br/>CoPilot</span> */}
                </div>
                <div className="imageMobile">
                    <div className="libraly-image"></div>
                </div>
                <div className='titleContainerLibraly'>
                    <div className='batchContainer'>
                        <span className='batch'>AI<br/>CoPilot</span>
                    </div>
                    <h1 className='libralyTitle' >EduPlanet</h1>
                </div>
            </div>
            <div className="image">
                <div className="libraly-image"></div>
            </div>
        </section>
    );
}

export default TelescopSection;
