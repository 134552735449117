
import React from 'react';
import '../css/sectionSixth.css';

const SectionSixth: React.FC = () => {
    return (
        <section className="sectionSixth">
            <div className="boxText boxTextLeft">
                <h2>COLLABORATION WITH THE UNIVERSITY</h2>
                <p className="textContainer">
                    The project was developed in close cooperation with experts and graduates of the 
                    Faculty of Electrical Engineering and Computer Science at the Slovak University of Technology 
                    in Bratislava, mainly specialists in the latest technologies in artificial intelligence, 
                    web technologies and automation.
                </p>
            </div>
            <div className="boxText boxTextRight">
                <h2>LET'S DO IT</h2>
                <p className="textContainer">
                    Are you ready to streamline your question processing? Contact us today to find out how EDUPLANET 
                    can simplify your everyday tasks. Leave routine matters to us and let’s focus on what’s truly important.
                </p>
            </div>
        </section>
    );
}

export default SectionSixth;
