
import React from 'react';
import Header from './components/Header';
import LibralySection from './components/LibralySection';
import SectionFirst from './components/SectionFirst';
import SectionSecond from './components/SectionSecond';
import SectionThird from './components/SectionThird';

import SectionFourth from './components/SectionFourth';
import SectionFifth from './components/SectionFifth';
import Collaboration from './components/SectionSixth';
import Footer from './components/Footer';

const App: React.FC = () => {
    return (
        <div className="App">
          {/* <Header /> */}
          {/* <LibralySection /> */}
          <div className='backgroundRadius' >
          <LibralySection />
            <div className='componentsContainer' >
              <SectionFirst />
            </div>
          </div>
          <SectionSecond />
          <SectionThird />
          <SectionFourth />
          <SectionFifth />
          <Collaboration />
          <Footer />
        </div>
    );
}

export default App;

