
import '../css/sectionSecond.css'

const SectionSecond: React.FC = () => {

    // const canvasRef = useRef<HTMLCanvasElement>(null);
    // const imgRef = useRef<HTMLImageElement>(null);

    // useEffect(() => {
    //     const img = imgRef.current;
    //     const canvas = canvasRef.current;
    //     const context = canvas?.getContext('2d');

    //     if (img && canvas && context) {
    //     img.onload = () => {
    //         canvas.width = img.width;
    //         canvas.height = img.height;

    //         context.drawImage(img, 0, 0, img.width, img.height);

    //         // Získajte pixelové dáta z ľavého horného rohu (alebo inej časti)
    //         const pixelData = context.getImageData(0, 0, 1, 1).data;

    //         // Prevod RGBA hodnôt na hexadecimálny formát
    //         const hexColor = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);

    //         // Zobrazte výsledok
    //         console.log(`Background color: ${hexColor}`);
    //         const colorResult = document.getElementById('colorResult');
    //         if (colorResult) {
    //         colorResult.innerText = `Background color: ${hexColor}`;
    //         colorResult.style.backgroundColor = hexColor;
    //         }
    //     };

    //     // Načítajte obrázok
    //     img.src = img.src; // Týmto spustíte udalosť `onload` pre obrázok, ak už bol načítaný
    //     }
    // }, []);

    // // Funkcia na prevod RGB hodnôt na hexadecimálny formát
    // const rgbToHex = (r: number, g: number, b: number): string => {
    //     return (
    //     '#' +
    //     [r, g, b]
    //         .map((x) => {
    //         const hex = x.toString(16);
    //         return hex.length === 1 ? '0' + hex : hex;
    //         })
    //         .join('')
    //         .toUpperCase()
    //     );
    // };
      

    return (
        <section className='sectionSecond-container'>
            <h1 className='sectionSecondTitle'>EduPlanet</h1>
            <div className='grafContainer'>
                <img src="/images/graf.png" alt="graf" />
            </div>
            <h1 className='sectionSecondTitle hidden'>EduPlanet</h1>
        </section>
    )
}

export default SectionSecond;


 {/* <img
        ref={imgRef}
        src="/images/graf.png"
        alt="Graph"
        style={{ display: 'none' }}
      />
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      <div id="colorResult">Color will be displayed here</div> */}