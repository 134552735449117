
import React from 'react';
import '../css/sectionFifth.css';

const SectionFifth: React.FC = () => {
    return (
        <section className="team-section">
            <h2 className='sectionFifthTitle'>
                BECOME A PART OF THE DIGITAL REVOLUTION IN PROFESSIONAL 
                INFORMATION EXTRACTION TODAY
            </h2>
            <div className="teamContainer">
                <div className="team-image">
                    <img src="/images/team.png" alt="The best team" />
                </div>
                <div className="team-content">
                    <h2>OUR TEAM</h2>
                    <p className='team-introduction'>We present to you a team of experts who are behind the development of the EDUPLANET platform.
                    </p>
                    <ul>
                        <li>Pavol Jelenek</li>
                        <li>Marek Vančo</li>
                        <li>Anton Blaščák</li>
                        <li>Jan Jakub Špuro</li>
                        <li>Jaroslav Šujan</li>
                        <li>Miša Jelenek</li>
                    </ul>
                    <p>
                        Our team’s drive enables us to perceive things from new perspectives and 
                        bring a fresh approach to problem-solving. Our team is fueled by the 
                        ambition to innovate. We are determined to deliver products that are not 
                        only successful but also contribute to European development and prosperity. 
                        We are excited for the journey ahead, which is united by innovation and success. 
                        We believe that transparency, open communication, and expert knowledge are key 
                        to successful product development and implementation.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default SectionFifth;
