
import React from 'react';

import '../css/sectionThird.css';

const SectionThird: React.FC = () => {
    return (
        <section className="container">
            <div className="containerLeft">
                <div className="boxText">
                    <h2>MOVING TOGETHER TOWARDS THE FUTURE</h2>
                    <p className='sectionFirst-text first-text'>
                        We are entering the era of digital automatisation, which is changing the way people approach learning, 
                        or information gathering in general. Having quality, up-to-date, verified data sources is key to the 
                        functioning of our system. The provided documents are completely under your own curation. 
                        Students get the newest curriculum and ever patient digital assistants personally tailored to them.
                    </p>
                </div>
                <div className="boxList">
                    <h2>WHAT ARE THE ADVANTAGES OF EDUPLANET?</h2>
                    <ul className="features-list">
                        <li>
                            <div className="process-number">
                                <span className='number'>
                                    1
                                </span>
                            </div>
                            <div className="process-text">
                                <h2>Speed</h2>
                                <p>Real time dialogue with your documents voiced by AI.</p>
                            </div>
                        </li>
                        <li>
                            <div className="process-number">
                                <span className='number'>
                                    2
                                </span>
                            </div>
                            <div className="process-text">
                                <h2>Accuracy</h2>
                                <p>The accuracy is guaranteed by your own context provided to the AI model.</p>
                            </div>
                        </li>
                        <li>
                            <div className="process-number">
                                <span className='number'>
                                    3
                                </span>
                            </div>
                            <div className="process-text">
                                <h2>Price</h2>
                                <p>Costs are significantly lower compared to consultations with an expert.</p>
                            </div>
                        </li>
                        <li>
                            <div className="process-number">
                                <span className='number'>
                                    4
                                </span>
                            </div>
                            <div className="process-text">
                                <h2>Availability</h2>
                                <p>No appointments, your personal learning assistant is available to you anytime, from any device, for an unlimited number of courses.</p>
                            </div>
                        </li>
                        <li>
                            <div className="process-number">
                                <span className='number'>
                                    5
                                </span>
                            </div>
                            <div className="process-text">
                                <h2>Reliability</h2>
                                <p>Based on the latest AI technologies and ever evolving software updates.</p>
                            </div>
                        </li>
                        <li>
                            <div className="process-number">
                                <span className='number'>
                                    6
                                </span>
                            </div>
                            <div className="process-text">
                                <h2>Security</h2>
                                <p>Your questions, answers, and information about you are not stored anywhere.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="containerRight">
                <div className="imageProcess">
                    <img src="/images/teacher.png" alt="process" />
                </div>
                <div className="boxText">
                    <h2>EDUPLANET AS AN INVESTMENT OPPORTUNITY</h2>
                    <p className='sectionFirst-text first-text'>
                        The learning procedure takes the shape of natural conversation 
                        with everchanging recommendations of questions you might want to ask, 
                        as well as pointers to similar topics. EDUPLANET provides you with proactive 
                        dialogue voiced by AI and fluently guides you through the document. 
                        Text-to-speech and speech-to-text functionalities are provided by the 
                        state-of-the-art AI models and can be easily interchangeable or turned off completely. 
                        Same goes for the pregenerated human-like 3D avatar fully lip-synced 
                        with the audio representation of your answer. With EDUPLANET you can easily 
                        generate entry and final tests to give your student a reference of his level 
                        (both open questions as well as the single choice versions are available). 
                        After mastering the learning process, students can easily generate a summarization 
                        of the topics covered in this lecture. If you don’t feel like finishing your course today, 
                        you can get back to your chat anytime and continue where you left off. 
                        Your curiosity and endurance are your only limits.
                    </p>
                </div>
            </div>
        </section>

    );
}

export default SectionThird;
